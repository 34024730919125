import React from "react";

import Header from "./Header";

const AdminLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <main
        className="main-wrapper"
        id="main-wrapper"
      >
        {children}
      </main>
    </div>
  );
};

export default AdminLayout;
